<template>
	<main-table-a class="oa navbar">
		<div slot="table" class="df f1">
			<div class="f1 pr20">
				<div class="mb20">
					<div class="mb10 c6">销售情况</div>
					<div class="p10 br5 bcf8f6f0">
						<div class="mb20">
							<div class="mb10 c6">今日</div>
							<div class="df">
								<div class="p10 f1 mr20 bce1d6ba">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">销量</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 单</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 f1 mr20 bce1d6ba">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">环比</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 单</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div>
							<div ref="parstMap"></div>
							<div ref="partMap"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="f3 pr20">
				<div class="mb10 c6">交付分布</div>
				<div ref="registerMap" style="width: 100%;" class="br5 p20 bcf8f6f0"></div>
			</div>
			<div class="f1">
				<div class="mb20 pr20">
					<div class="mb10 c6">订单提醒</div>
					<div class="df br5 fww bcf8f6f0">
						<div class="p10 " style="width: 33%; min-width: 70px;">
							<h5 class="c6 mb5">待付款</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 单</p>
						</div>
						<div class="p10 " style="width: 33%; min-width: 70px;">
							<h5 class="c6 mb5">待交付</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 单</p>
						</div>
						<div class="p10 " style="width: 33%; min-width: 70px;">
							<h5 class="c6 mb5">待收货</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 单</p>
						</div>
						<div class="p10 " style="width: 33%; min-width: 70px;">
							<h5 class="c6 mb5">售后中</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 单</p>
						</div>
						<div class="p10 " style="width: 33%; min-width: 70px;">
							<h5 class="c6 mb5">合同到期</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 单</p>
						</div>
					</div>
				</div>
				<div class="mb20 pr20">
					<div class="mb10 c6">产库存情况</div>
					<div class="p10 br5 bcf8f6f0 f1">
						<div class="p5 df aic jcsb">
							<h5 class="c6">深圳公积金</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档医疗保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档生育保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档工伤保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档失业保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档养老保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import chinaJson from "@/utils/chinaCity.json";
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		data() {
			return {
				charts: []
			}
		},
		mounted() {
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			setElementHeight (e, s = 1.4) {
				// 获取元素的宽度
				const { offsetWidth } = this.$refs[e];
				// 设置元素的高度
				this.$refs[e].style.height = `${offsetWidth / s}px`;
			},
			initChart () {
				this.setElementHeight('registerMap');
				this.setElementHeight('parstMap', 1);
				this.setElementHeight('partMap', 1);
				this.initMap('registerMap');
				this.initPie('parstMap', '产品占比');
				this.initPie('partMap', '平台占比');
				this.charts['registerMap'].resize();
			},
			initMap (e) {
				// 注册地图数据
				echarts.registerMap('china', chinaJson);
				// 初始化 ECharts 实例并设置配置项
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					visualMap: {
						min: 0,
						max: 2000,
						left: 'left',
						top: 'bottom',
						text: ['高','低'],
						calculable: true
					},
					series: [
						{
							type: 'map',
							map: 'china',
							layoutCenter: ['50%', '70%'],
							layoutSize: '136%',
						}
					]
				};
				this.charts[e].setOption(option);
			},
			initPie (e, text = '部门占比') {
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					title: {
						text,
						left: 'center',
					},
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '60%',
							data: [
								{ value: 1048, name: 'Search Engine' },
								{ value: 735, name: 'Direct' },
								{ value: 580, name: 'Email' },
								{ value: 484, name: 'Union Ads' },
								{ value: 300, name: 'Video Ads' }
							],
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				};
				option && this.charts[e].setOption(option);
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.initChart);
			if (this.charts['registerMap']) {
				this.charts['registerMap'].dispose();
			}
		}
	}
</script>