<template>
	<component :is="work_bench"></component>
</template>


<script>
	import { mapState, mapActions } from 'vuex';
	import * as echarts from 'echarts';
	import crm from '@/components/workbenches/crm';
	import hrm from '@/components/workbenches/hrm';
	import fms from '@/components/workbenches/fms';
	import oms from '@/components/workbenches/oms';
	import wms from '@/components/workbenches/wms';

	export default {
		components: {
			crm,
			hrm,
			fms,
			oms,
			wms
		},
		computed: {
			...mapState(['hasLogin', 'history_routes', 'admin_permissions', 'theme', 'work_bench', 'admin_user']),
			year () {
				var d = new Date();
				return d.getFullYear();
			},
			height () {
				return this.theme.height - 180;
			}
		},
		filters: {
			chainType (v) {
				var t = "昨天";
				if (v == 'week') {
					t = "上周";
				}
				if (v == 'month') {
					t = "上月";
				}
				if (v == 'year') {
					t = "去年";
				}
				return t;
			}
		},
		methods: {
			...mapActions(['logout']),
			handleCommand(cmd) {
				if (cmd == 'logout') {
					this.$confirm('此操作注销退出系统, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$message({
							type: 'success',
							message: '注销成功！正在退出系统...'
						});
					});
				}
			},
			async querySearchAsync (q, cb) {
				if (!q) return false;
				let routes = this.admin_permissions.filter(r => (r.name.toLowerCase().indexOf(q.toLowerCase()) === 0 && r.action === 'default'));
				let results = routes.map((c) => ({value: c.name, path: c.path}));
				cb(results);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
				this.employees = result.employees;
				if (result.performance_objective) {
					this.initObjective(result.performance_objective.personage, result.performance_objective.team);
				}
				if (result.sales_performance) {
					this.initPerformance(result.sales_performance);
				}
				if (result.sales_trend) {
					this.initTrend(result.sales_trend);
				}
			},
			showRouteInput () {
				this.add_route = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			blurInput () {
				if (!this.search_route) {
					this.add_route = false;
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_objective':
							this.submitPerformanceObjectives(this.objective);
						break;
					}
				})
			},
			async submitPerformanceObjectives (data) {
				const res = await this.$http.post(this.$api.URI_PERFORMANCES_OBJECTIVES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.create_dialog = false;
					}
				});
			},
			onRoute (p) {
				const { path } = this.$route;
				if (p === path) return false;
				this.$router.push({path: p});
			},
			removeHistory (p) {
				this.$confirm('确定要删除【'+p.name+'】吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.$store.commit('setHistoryRoutes', this.history_routes.filter(history => history.path !== p.path));
				});
			},
			initEchart (id, option) {
				option && echarts.init(document.getElementById(id)).setOption(option);
			},
			initObjective (p = 0, t = 0) {
				const gaugeData = [
					{
						value: t,
						name: '团队目标',
						title: {
							offsetCenter: ['0%', '-45%']
						},
						detail: {
							valueAnimation: true,
							offsetCenter: ['0%', '-20%']
						}
					}, {
						value: p,
						name: '个人目标',
						title: {
							offsetCenter: ['0%', '10%']
						},
						detail: {
							valueAnimation: true,
							offsetCenter: ['0%', '35%']
						}
					}
				];
				const { is_mobile } = this.theme;
				const option = {
					title: {
						text: '绩效目标'
					},
					series: [
						{
							type: 'gauge',
							startAngle: 90,
							endAngle: -360,
							pointer: {
								show: false
							},
							progress: {
								show: true,
								overlap: false,
								roundCap: true,
								clip: false
							},
							axisLine: {
								lineStyle: {
									width: is_mobile ? 20 : 30
								}
							},
							splitLine: {
								show: false,
								distance: 0,
								length: 10
							},
							axisTick: {
								show: false
							},
							axisLabel: {
								show: false,
								distance: 50
							},
							data: gaugeData,
							title: {
								fontSize: 12
							},
							detail: {
								width: is_mobile ? 30 : 50,
								height: is_mobile ? 6 : 12,
								fontSize: 12,
								color: 'inherit',
								borderColor: 'inherit',
								borderRadius: 20,
								borderWidth: 1,
								formatter: '{value}%'
							}
						}
					]
				};
				this.initEchart('performance_objective', option);
			},
			initTrend (source) {
				const option = {
					title: {
						text: '销售趋势'
					},
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: 30,
						right: 30,
						bottom: 60,
					},
					legend: {
						bottom: 0
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
					},
					yAxis: {
						type: 'value'
					},
					series: source.map((d) => {
						return {
							...d,
							areaStyle: d.areaStyle ? {
								...d.areaStyle,
								color: d.areaStyle.color ? new echarts.graphic.LinearGradient(0, 0, 0, 1, d.areaStyle.color) : ''
							} : {}
						}
					})
				};
				this.initEchart('sales_trend', option);
			},
			initPerformance (source) {
				const option = {
					title: {
						text: '销售金额'
					},
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: 40,
						right: 40,
						bottom: 60,
						containLabel: false
					},
					legend: {
						bottom: 0
					},
					dataset: {
						dimensions: source.length ? source[0].keys : [],
						source
					},
					xAxis: {
						type: 'category',
						boundaryGap: false
					},
					yAxis: {
						type: 'value'
					},
					series: [
						{ type: 'line', showSymbol: false },
						{ type: 'line', showSymbol: false },
						{ type: 'line', showSymbol: false }
					]
				};

				this.initEchart('sales_performance', option);
			}
		},
		data() {
			const t = new Date();
			t.setTime(t.getTime() - 3600 * 1000 * 24 * 365);
			return {
				data: {},
				employees: [],
				params: {
					type: 1,
					cycle: 'day',
					performance: [t, new Date()],
					trend: [t, new Date()],
				},
				objective: {},
				search_route: '',
				add_route: false,
				create_dialog: false,
				objective_drawer: false,
				timer: null,
				colors: [
					{color: '#1989fa', percentage: 60},
					{color: '#f90', percentage: 90},
					{color: '#f00', percentage: 100}
				]
			}
		},
		async mounted () {
			
		},
		created () {

		},
		beforeDestroy() {

		}
	};
</script>
