<template>
	<main-table-a class="oa navbar">
		<div slot="table" class="df f1">
			<div class="f4 pr20">
				<div class="mb10 c6">物料情况</div>
				<div class="p10 br5 df bcf8f6f0">
					<div class="f1">
						<div class="p5 df aic jcsb">
							<h5 class="c6">深圳公积金</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档医疗保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档生育保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档工伤保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档失业保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
						<div class="p5 df aic jcsb">
							<h5 class="c6">非深户二档养老保险</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b></p>
						</div>
					</div>
					<div class="f4" ref="pie" style="width: 100%; height: 320px;">f4</div>
				</div>
			</div>
			<div class="f1">
				<div class="mb20">
					<div class="mb10 c6">待办提醒</div>
					<div class="br5 p10 bcf8f6f0">
						<div class="mb20">
							<div class="mb5 c6">物料采购</div>
							<div class="df jcsb fww">
								<div class="p10 mb10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 mb10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待采购</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待入库</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">物料盘点</div>
							<div class="df jcsb">
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">物料调拨</div>
							<div class="df jcsb">
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">物料配送</div>
							<div class="df jcsb fww">
								<div class="p10 mb10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 mb10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 48%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待配送</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		data() {
			return {
				charts: []
			}
		},
		mounted() {
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			initChart () {
				this.initPie('pie');
				this.charts['pie'].resize();
			},
			initPie (e) {
				this.charts[e] = echarts.init(this.$refs[e]);
				var option;

				const data = [
					{
						name: 'Apples',
						value: 70
					}, {
						name: 'Strawberries',
						value: 68
					}, {
						name: 'Bananas',
						value: 48
					}, {
						name: 'Oranges',
						value: 40
					}, {
						name: 'Pears',
						value: 32
					}, {
						name: 'Pineapples',
						value: 27
					}, {
						name: 'Grapes',
						value: 18
					}
				];
				option = {
					title: [
						{
							subtext: '物料类别',
							left: '20%',
							textAlign: 'center'
						}, {
							subtext: '品牌占比',
							left: '50%',
							textAlign: 'center'
						}, {
							subtext: '供应占比',
							left: '80%',
							textAlign: 'center'
						}
					],
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '50%',
							data: [
								{
									name: '男',
									value: 70
								}, {
									name: '女',
									value: 68
								}, {
									name: '未知',
									value: 68
								}
							],
							right: '60%'
						}, {
							type: 'pie',
							radius: '50%',
							data: data,
							right: 0
						}, {
							type: 'pie',
							radius: '50%',
							data: data,
							left: '60%'
						}
					]
				};

				option && this.charts[e].setOption(option);
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.initChart);
			if (this.charts['pie']) {
				this.charts['pie'].dispose();
			}
		}
	}
</script>