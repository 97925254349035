<template>
	<main-table-a>
		<div slot="table" class="df f1 aifs">
			<div class="f1 mb20 pr20">
				<div class="mb10 c6">上月薪资情况</div>
				<div class="df br5 jcsb bcf8f6f0">
					<div class="p10" style="min-width: 100px;">
						<div class="mb20">
							<h5 class="c6 mb5">计薪人员</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
						</div>
						<div class="mb20">
							<h5 class="c6 mb5">待签名确认</h5>
							<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
						</div>
						<div class="mb20">
							<h5 class="c6 mb5">实发工资</h5>
							<p class="fs12"><b class="fs18 cb7a06b">286899</b> 元</p>
						</div>
					</div>
					<div class="p10 tac" ref="partMap" style="width: 100%; height: 400px;"></div>
				</div>
			</div>
			<div class="f1 mb20 pr20">
				<div class="mb10 c6">上月收支情况</div>
				<div class="df br5 jcsb bcf8f6f0">
					<div class="p10" style="min-width: 160px;">
						<div class="mb10">
							<h5 class="c6 mb5">回款总额</h5>
							<p class="fs12"><b class="fs18 cb7a06b">286899</b> 元</p>
						</div>
						<div class="mb10">
							<h5 class="c6 mb5">付款总额</h5>
							<p class="fs12"><b class="fs18 cb7a06b">286899</b> 元</p>
						</div>
						<div class="mb10">
							<h5 class="c6 mb5">报销总额</h5>
							<p class="fs12"><b class="fs18 cb7a06b">286899</b> 元</p>
						</div>
						<div class="mb10">
							<h5 class="c6 mb5">预估利润</h5>
							<p class="fs12"><b class="fs18 cb7a06b">286899</b> 元</p>
						</div>
					</div>
					<div class="p10 tac" ref="zhichuMap" style="width: 100%; height: 400px;"></div>
				</div>
			</div>
			<div class="f1">
				<div class="mb20">
					<div class="mb10 c6">待办提醒</div>
					<div class="br5 p10 bcf8f6f0">
						<div class="mb20">
							<div class="mb5 c6">开票申请</div>
							<div class="df jcsb">
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待开票</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">付款申请</div>
							<div class="df jcsb">
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待付款</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">费用报销</div>
							<div class="df jcsb">
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待报销</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div class="mb20">
							<div class="mb5 c6">客户回款</div>
							<div class="df">
								<div class="p10 bce1d6ba mr15" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">待审核</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 bce1d6ba" style="width: 31%;">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">审核中</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 份</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		data() {
			return {
				charts: [],
				data: [
					{ name: '北京', value: 1200 },
					{ name: '上海', value: 900 },
					{ name: '广东', value: 1500 },
					{ name: '四川', value: 800 },
					{ name: '浙江', value: 1100 },
					{ name: '江苏', value: 1300 },
					{ name: '山东', value: 1000 },
				]
			}
		},
		mounted() {
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			initChart () {
				this.initPie('partMap', '部门占比');
				this.initPie('zhichuMap', '支出占比');
			},
			initPie (e, text = '部门占比') {
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					title: {
						text,
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						bottom: 'bottom'
					},
					series: [
						{
							type: 'pie',
							radius: '50%',
							data: [
								{ value: 1048, name: 'Search Engine' },
								{ value: 735, name: 'Direct' },
								{ value: 580, name: 'Email' },
								{ value: 484, name: 'Union Ads' },
								{ value: 300, name: 'Video Ads' }
							],
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				};
				option && this.charts[e].setOption(option);
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.initChart);
		}
	}
</script>