<template>
	<main-table-a class="oa navbar">
		<div slot="table">
			<div class="df f1">
				<div class="f5 df fdc">
					<div class="mb20 pr20">
						<div class="mb10 c6">人员概况</div>
						<div class="df br5 bcf8f6f0">
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">在职</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">全职</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">兼职</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">实习</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">外包</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">劳务</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">顾问</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
							<div class="p10 f1" style="min-width: 90px;">
								<h5 class="c6 mb5">返聘</h5>
								<p class="fs12"><b class="fs18 cb7a06b">99</b> 人</p>
							</div>
						</div>
					</div>
					<div class="df fww f1">
						<div class="pr20 mb20 f1">
							<div class="mb10 c6">籍贯分布</div>
							<div ref="birthMap" style="width: 100%;" class="br5 bcf8f6f0"></div>	
						</div>
						<div class="pr20 mb20 f1">
							<div class="mb10 c6">户籍分布</div>
							<div ref="registerMap" style="width: 100%;" class="br5 bcf8f6f0"></div>	
						</div>
					</div>
				</div>
				<div class="f2 df fdc">
					<div class="mb20">
						<div class="mb10 c6">待办提醒</div>
						<div class="df br5 fww bcf8f6f0">
							<div class="p10" style="width: 33%; min-width: 70px;" v-for="reminder in data.reminder" :key="reminder.name">
								<h5 class="c6 mb5">{{reminder.name}}</h5>
								<p class="fs12"><b class="fs18 cb7a06b">{{reminder.value}}</b> 人</p>
							</div>
						</div>
					</div>
					<div class="mb20 f1 df fdc">
						<div class="mb10 c6">投保情况</div>
						<div class="p10 br5 bcf8f6f0 f1">
							<div class="p5 df aic jcsb" v-for="insurance in data.insurance" :key="insurance.name">
								<h5 class="c6">{{insurance.name}}</h5>
								<p class="fs12"><b class="fs18 cb7a06b">{{insurance.value}}</b> 人</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="df fww f1" ref="pie" style="width: 100%; height: 240px;"></div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import chinaJson from "@/utils/chinaCity.json";
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		data () {
			return {
				timer: null,
				charts: [],
				params: {
					action: 'hrm'
				},
				data: {}
			}
		},
		async mounted () {
			await this.getHome();
			this.timer = setInterval(() => { this.getHome(); }, 4000);
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			setElementHeight (e, s = 1.4) {
				// 获取元素的宽度
				const { offsetWidth } = this.$refs[e];
				// 设置元素的高度
				this.$refs[e].style.height = `${offsetWidth / s}px`;
			},
			initChart () {
				this.setElementHeight('birthMap');
				this.setElementHeight('registerMap');
				this.initMap('birthMap', this.data.birth || []);
				this.initMap('registerMap', this.data.register || []);
				this.initPie('pie', this.data.pie || {});
				this.charts['pie'].resize();
				this.charts['birthMap'].resize();
				this.charts['registerMap'].resize();
			},
			initMap (e, data) {
				// 注册地图数据
				echarts.registerMap('china', chinaJson);
				// 初始化 ECharts 实例并设置配置项
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					visualMap: {
						min: 0,
						max: 2000,
						left: 'left',
						top: 'bottom',
						text: ['高','低'],
						calculable: true
					},
					series: [
						{
							type: 'map',
							map: 'china',
							layoutCenter: ['50%', '70%'],
							layoutSize: '136%',
							data
						}
					]
				};
				this.charts[e].setOption(option);
			},
			initPie (e, d = {}) {
				this.charts[e] = echarts.init(this.$refs[e]);

				// const data = [
				// 	{
				// 		name: 'Apples',
				// 		value: 70
				// 	}, {
				// 		name: 'Strawberries',
				// 		value: 68
				// 	}, {
				// 		name: 'Bananas',
				// 		value: 48
				// 	}, {
				// 		name: 'Oranges',
				// 		value: 40
				// 	}, {
				// 		name: 'Pears',
				// 		value: 32
				// 	}, {
				// 		name: 'Pineapples',
				// 		value: 27
				// 	}, {
				// 		name: 'Grapes',
				// 		value: 18
				// 	}
				// ];
				var option = {
					title: [
						{
							subtext: '男女比例',
							left: '10%',
							textAlign: 'center'
						}, {
							subtext: '民族占比',
							left: '30%',
							textAlign: 'center'
						}, {
							subtext: '婚姻情况',
							left: '50%',
							textAlign: 'center'
						}, {
							subtext: '政治面貌',
							left: '70%',
							textAlign: 'center'
						}, {
							subtext: '最高学历',
							left: '90%',
							textAlign: 'center'
						}
					],
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '50%',
							data: d.gender || [],
							right: '79%'
						}, {
							type: 'pie',
							radius: '50%',
							data: d.nation || [],
							right: '39%'
						}, {
							type: 'pie',
							radius: '50%',
							data: d.marriage || [],
							left: '1%'
						}, {
							type: 'pie',
							radius: '50%',
							data: d.politics || [],
							left: '41%'
						}, {
							type: 'pie',
							radius: '50%',
							data: d.education || [],
							left: '80%'
						}
					]
				};

				option && this.charts[e].setOption(option);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
			}
		},
		beforeDestroy () {
			clearInterval(this.timer);
			this.timer = null;
			window.removeEventListener('resize', this.initChart);
			if (this.charts['birthMap']) {
				this.charts['birthMap'].dispose();
			}
			if (this.charts['registerMap']) {
				this.charts['registerMap'].dispose();
			}
			if (this.charts['pie']) {
				this.charts['pie'].dispose();
			}
		}
	}
</script>